import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUser, FaLock } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
`;

const LoginBox = styled.div`
  background: rgba(23, 23, 23, 0.9);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  color: #ea864d;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  position: absolute;
  left: 10px;
  color: #ea864d;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
  font-size: 1rem;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #ea864d;
  }
`;

const SubmitButton = styled.button`
  background-color: #ea864d;
  color: white;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: #FF8C00;
    transform: scale(1.05);
  }
`;

const ForgotPassword = styled.a`
  display: block;
  margin-top: 10px;
  color: #ea864d;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        navigate('/dashboard');
      } else {
        alert('Неверный логин или пароль');
      }
    } catch (error) {
      console.error('Ошибка при авторизации:', error);
      alert('Произошла ошибка при авторизации');
    }
  };

  return (
    <Container>
      <LoginBox>
        <Title>Авторизация</Title>
        <form onSubmit={handleLogin}>
          <FormGroup>
            <Icon><FaUser /></Icon>
            <Input
              type="text"
              id="username"
              placeholder="Логин"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Icon><FaLock /></Icon>
            <Input
              type="password"
              id="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormGroup>
          <SubmitButton type="submit">Войти</SubmitButton>
        </form>
        <ForgotPassword href="/forgot-password">Забыли пароль?</ForgotPassword>
      </LoginBox>
    </Container>
  );
};

export default Login;