import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FAQContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  color: #fff;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 1s ease-in-out;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px 10px;
    background-size: contain;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const QuestionItem = styled.div`
  background: #2a2a2a;
  border-radius: 8px;
  padding: 15px 20px;
  cursor: pointer;
  transition: background 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    background: #333;
  }

  &.active {
    background: #444;
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 600;
`;

const Question = styled.div`
  flex: 1;
`;

const IconWrapper = styled.div`
  margin-left: 10px;
`;

const Answer = styled.div`
  font-size: 1rem;
  color: #ccc;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding: 0;

  &.active {
    max-height: 500px;
    padding: 10px 0;
  }

  a {
    color: #ea864d;
    text-decoration: underline;
  }
`;

const SupportText = styled.p`
  margin-top: 40px;
  text-align: center;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;

  a {
    color: #ea864d;
    text-decoration: underline;
  }
`;

const AccordionItem = ({ question, answer, isActive, onClick }) => (
  <QuestionItem onClick={onClick} className={isActive ? 'active' : ''}>
    <QuestionHeader>
      <Question>{question}</Question>
      <IconWrapper>{isActive ? <FaChevronUp /> : <FaChevronDown />}</IconWrapper>
    </QuestionHeader>
    <Answer className={isActive ? 'active' : ''} dangerouslySetInnerHTML={{ __html: answer }} />
  </QuestionItem>
);

const AccordionColumn = ({ items, activeIndexes, toggleAnswer }) => (
  <Column>
    {items.map((faq) => (
      <AccordionItem
        key={faq.key}
        question={faq.question}
        answer={faq.answer}
        isActive={activeIndexes.includes(faq.key)}
        onClick={() => toggleAnswer(faq.key)}
      />
    ))}
  </Column>
);

const FAQ = () => {
  const [commonActiveIndexes, setCommonActiveIndexes] = useState([]);
  const [ingameActiveIndexes, setIngameActiveIndexes] = useState([]);

  const toggleCommonAnswer = (key) => {
    setCommonActiveIndexes((prev) =>
      prev.includes(key) ? prev.filter((index) => index !== key) : [...prev, key]
    );
  };

  const toggleIngameAnswer = (key) => {
    setIngameActiveIndexes((prev) =>
      prev.includes(key) ? prev.filter((index) => index !== key) : [...prev, key]
    );
  };

  const commonQuestions = [
    { key: 'cq1', question: 'Можно ли играть со смартфона?', answer: '<b>К сожалению</b>, игра предусмотрена только на ПК.' },
    { key: 'cq2', question: 'Как мне зайти на другие сервера?', answer: 'Наша сборка устанавливается в отдельную папку со своим лаунчером. <br> Чтобы зайти на другие сервера, Вам нужно поменять путь в настройках SA:MP клиента.' },
    { key: 'cq3', question: 'Слабый компьютер, лаги в игре', answer: '1) Существует актуальная <a href="#">тема на форуме</a>.<br><br> 2) Изменить настройки дополнительной графики: дальность прорисовки и Anti Aliasing на минимум.<br><br><img class="display__image" src="/assets/images/display.jpg" alt="">' },
    { key: 'cq4', question: 'При подключении обнаружена ошибка лаунчера', answer: '1) Убедитесь, что вы запускаете лаунчер от имени администратора и загружена последняя версия лаунчера (запускаем update.exe).<br><br> 2) Проверьте доступность сайта <a href="http://pg-server.ru/">pg-server.ru</a>, так как через него работает лаунчер. Если IP не совпадают — необходимо подождать 5 минут и попробовать снова' },
    { key: 'cq5', question: 'Не работает голосовой чат', answer: 'Скачайте и установите драйвер <a href="http://dl.premier-game.ru/drivers/8.VCRHyb86.exe">VCRHyb86.exe</a>.' },
    { key: 'cq6', question: 'Какой IP у сервера?', answer: 'На сервере <b>нет IP</b>. <br>Играть возможно только через наш собственный лаунчер.' },
    { key: 'cq7', question: 'Ошибки .dll и .asi', answer: 'Ошибка <span class="moreinfo">Hooks.asi</span>:<br> Рекомендуем обновить <a href="#">Microsoft Visual</a> и <a href="#">DirectX</a><br><br> Ошибка <span class="moreinfo">msvcr100.dll</span>:<br> Скачайте файл <a href="#">msvcr100.dll</a><br> Также, рекомендуем обновить драйвер <a href="#">Microsoft Visual</a><br><br> Ошибка <span class="moreinfo">msvcp140.dll</span>:<br> Скачайте файл <a href="#">msvcp140.dll</a><br> Также, рекомендуем обновить драйвер <a href="#">Microsoft Visual</a><br><br> Ошибка <span class="moreinfo">d3dx9_25.dll</span>:<br> Скачайте файл <a href="#">d3dx9_25.dll</a><br> Также, рекомендуем обновить драйвер <a href="#">Microsoft Visual</a><br><br> • DLL файлы для 32 битных систем загрузить в папку: <b>C:\\Windows\\System32;</b><br> • DLL файлы для 64 битных систем загрузить в папку: <b>C:\\Windows\\SysWOW64;</b>' },
    { key: 'cq8', question: 'Игра зависает на середине', answer: 'Рекомендуем обновить драйвер <a href="#">Microsoft Visual</a>.' },
    { key: 'cq9', question: 'Сворачивается игра и ничего не происходит', answer: '1) Установите игру на диск C, укажите в настройках путь к игре и попробуйте снова.<br><br> 2) Запустите лаунчер от имени администратора.' },
    { key: 'cq10', question: 'Пропал / не работает лаунчер', answer: 'Запустить <b>update.exe</b> в папке с игрой.' },
  ];

  const ingameQuestions = [
    { key: 'igq1', question: 'Основные правила проекта', answer: 'Основные правила и наказание за нарушения, указаны в <a href="https://forum.premier-game.ru/threads/nakazanija-za-narushenija.2/">теме на форуме</a>.' },
    { key: 'igq2', question: 'Скорость, цена и ID автомобилей', answer: 'Указано в <a href="https://forum.premier-game.ru/threads/avtomobili-servera.732/">теме на форуме</a>.' },
    { key: 'igq3', question: 'Важный раздел информации для новичков', answer: '<a href="https://forum.premier-game.ru/forums/informacija-dlja-novichkov-faq.25/">Перейти на форумный раздел</a>.' },
    { key: 'igq4', question: 'Где купить транспортное средство?', answer: '1) Через заказ гос. авто (/gps)<br><br> 2) Также доступна покупка транспортного средства с рук у человека, <b>будьте внимательны при покупке с рук!</b>' },
    { key: 'igq5', question: 'Не работает голосовой чат', answer: 'Скачайте и установите драйвер <a href="http://dl.premier-game.ru/drivers/8.VCRHyb86.exe">VCRHyb86.exe</a>.' },
    { key: 'igq6', question: 'Администратор выдал наказание просто так', answer: 'Пишем жалобу на администратора строго <a href="https://forum.premier-game.ru/forums/zhaloby-na-administraciju.21/">по шаблону</a>.' },
    { key: 'igq7', question: 'Фотографии транспортных средств', answer: 'Имеются в <a href="https://vk.com/premier_game?z=album-52756770_272373123">альбоме группы ВК</a>.' },
    { key: 'igq8', question: 'Работы на сервере', answer: 'Указано в <a href="https://forum.premier-game.ru/threads/raboty-servera.24/">теме на форуме</a>.' },
    { key: 'igq9', question: 'Где купить недвижимость?', answer: '1) По всему городу расставлено множество домов и гаражей: Вы можете найти их и купить.<br><br> 2) Также доступна покупка недвижимости с рук у человека, <b>будьте внимательны при покупке с рук!</b>' },
    { key: 'igq10', question: 'Обманули на имущество / не отдали долг', answer: '1) В долг даете на свой страх и риск. Если вам не отдали долг — это ваши проблемы, <b>жалобы не рассматриваются</b>!<br><br> 2) Обман на имущество при продаже — <a href="https://forum.premier-game.ru/forums/obman-na-imuschestvo.39/">пишем жалобу на игрока</a>.' },
  ];

  return (
    <FAQContainer>
      <Title>Ответы на часто задаваемые вопросы</Title>
      <QuestionsContainer>
        <AccordionColumn
          items={commonQuestions.slice(0, 5)}
          activeIndexes={commonActiveIndexes}
          toggleAnswer={toggleCommonAnswer}
        />
        <AccordionColumn
          items={commonQuestions.slice(5)}
          activeIndexes={commonActiveIndexes}
          toggleAnswer={toggleCommonAnswer}
        />
      </QuestionsContainer>
      <Title>Внутриигровые вопросы</Title>
      <QuestionsContainer>
        <AccordionColumn
          items={ingameQuestions.slice(0, 5)}
          activeIndexes={ingameActiveIndexes}
          toggleAnswer={toggleIngameAnswer}
        />
        <AccordionColumn
          items={ingameQuestions.slice(5)}
          activeIndexes={ingameActiveIndexes}
          toggleAnswer={toggleIngameAnswer}
        />
      </QuestionsContainer>
      <SupportText>
        Не нашли решение? Обратитесь в <a href="https://vk.me/join/Vq6_JScWoZDeCMzjxGlq/dnJ8gqE7/o79BI=">поддержку</a>.
      </SupportText>
    </FAQContainer>
  );
};

export default FAQ;