import React, { useEffect } from 'react';
import styled from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import discordLogo from './assets/images/discord.svg';
import youtubeLogo from './assets/images/youtube.png';
import vkLogo from './assets/images/vk.svg';
import backgroundImage from './assets/images/bg-down.png';
import vkImage from './assets/images/vk-image.png';

const SocialContainer = styled.section`
  padding: 4rem 2rem;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  text-align: center;
  position: relative;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    background-attachment: scroll;
    background-position: center top;
  }
`;

const Title = styled.div`
  margin-bottom: 80px;
  font-size: 35px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 2;
  font-family: 'Acrom', sans-serif;
  &:after {
    content: 'SOCIAL';
    font-size: 150px;
    color: rgba(255, 255, 255, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 40px;

    &:after {
      font-size: 80px;
    }
  }
`;

const SocialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 2rem;
  justify-items: center;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 50px;
  }
`;

const SocialBlock = styled.div`
  text-align: left;
  position: relative;

  &.discord {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &.youtube {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  &.vk {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    height: 100%;
  }

  @media (max-width: 768px) {
    text-align: center;

    &.discord, &.youtube, &.vk {
      grid-column: auto;
      grid-row: auto;
      height: auto;
    }
  }
`;

const SocialTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const SocialText = styled.p`
  margin: 1rem 0;
  color: #ddd;
  text-align: center;
`;

const SocialLink = styled.a`
  display: block;
  padding: 20px 45px;
  margin-top: 30px;
  background: #ea864d;
  color: #171717;
  text-decoration: none;
  border-radius: 4px;
  text-align: center;
  font-weight: 700;
  box-shadow: 10px 0px 70px #ea864d;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background: #d97842;
    transform: scale(1.05);
    color: #fff;
    box-shadow: 10px 0px 70px #d97842;
  }

  @media (max-width: 768px) {
    padding: 15px 30px;
  }
`;

const SocialStats = styled.p`
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 0.5rem;
  text-align: center;
`;

const SocialImage = styled.img`
  width: 260px;
  height: 237px;
  margin-top: 1rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const SocialLinks = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <SocialContainer id="social">
      <Title>Социальные сети</Title>
      <SocialGrid>
        <SocialBlock className="discord" data-aos="fade-up">
          <SocialTitle>
            <SocialIcon src={discordLogo} alt="Discord" />
            Discord
          </SocialTitle>
          <SocialText>Общение с игроками проекта</SocialText>
          <SocialLink href="https://discord.com/invite/MrAhwQhWng">Подключиться</SocialLink>
          <SocialStats>Более <b>2 100</b> участников</SocialStats>
        </SocialBlock>
        <SocialBlock className="youtube" data-aos="fade-up" data-aos-delay="200">
          <SocialTitle>
            <SocialIcon src={youtubeLogo} alt="YouTube" />
            YouTube
          </SocialTitle>
          <SocialText>Обзорные ролики обновлений</SocialText>
          <SocialLink href="https://www.youtube.com/user/PremierGameRu">Подписаться</SocialLink>
          <SocialStats>Более <b>4 100</b> подписчиков</SocialStats>
        </SocialBlock>
        <SocialBlock className="vk" data-aos="fade-up" data-aos-delay="400">
          <SocialTitle>
            <SocialIcon src={vkLogo} alt="ВКонтакте" />
            ВКонтакте
          </SocialTitle>
          <SocialText>Сообщество с новостями проекта</SocialText>
          <SocialImage src={vkImage} alt="VK Community" />
          <SocialLink href="https://vk.com/premier_game">Подписаться</SocialLink>
          <SocialStats>Более <b>43 000</b> участников</SocialStats>
        </SocialBlock>
      </SocialGrid>
    </SocialContainer>
  );
};

export default SocialLinks;
