import React from 'react';
import styled from 'styled-components';
import maintenanceBg from './assets/images/background.jpg';

const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: url(${maintenanceBg}) no-repeat center center fixed;
  background-size: cover;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Countdown = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  div {
    margin: 0 10px;
    text-align: center;

    @media (max-width: 768px) {
      margin: 0 5px;
    }
  }

  span {
    display: block;
    font-size: 2rem;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  small {
    display: block;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 0.75rem;
    }
  }
`;

const ContactInfo = styled.div`
  margin-top: 2rem;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }

  a {
    color: #ffcc00;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const MaintenancePage = () => {
  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  React.useEffect(() => {
    const targetDate = new Date('2024-06-30T23:59:59');

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <MaintenanceContainer>
      <Title>Технические работы!</Title>
      <Subtitle>В настоящее время наш веб-сайт проходит плановое техническое обслуживание.</Subtitle>
      <Countdown>
        <div>
          <span>{timeLeft.days}</span>
          <small>Дней</small>
        </div>
        <div>
          <span>{timeLeft.hours}</span>
          <small>Часов</small>
        </div>
        <div>
          <span>{timeLeft.minutes}</span>
          <small>Минут</small>
        </div>
        <div>
          <span>{timeLeft.seconds}</span>
          <small>Секунд</small>
        </div>
      </Countdown>
      <ContactInfo>
        Следите за новостями в нашем сообществе ВКонтакте — <a href="https://vk.com/premier_game">PREMIER GAME</a>.
      </ContactInfo>
    </MaintenanceContainer>
  );
};

export default MaintenancePage;