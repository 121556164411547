import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import backgroundImage from './assets/images/donate-bg.jpg';
import { FaLevelUpAlt, FaMoneyBillWave, FaPiggyBank, FaPhone, FaGift, FaBuilding, FaBriefcase, FaHome, FaStore, FaGenderless, FaCrown } from 'react-icons/fa';
import { GiPistolGun } from 'react-icons/gi';
import { GrUserWorker } from 'react-icons/gr';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const grow = (width) => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${width};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  animation: ${fadeIn} 1s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-top: 100px;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const ProfileCard = styled.div`
  background: rgba(23, 23, 23, 0.9);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 0.5rem;
  }
`;

const UserInfo = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserName = styled.h3`
  margin: 0;
  color: #ea864d;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const VIPBadge = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const CrownIcon = styled(FaCrown)`
  color: gold;
  font-size: 1.5rem;
  margin-left: 5px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
`;

const UserID = styled.p`
  margin: 5px 0;
  color: #ea864d;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  width: calc(100% - 20px);
  margin: 0 10px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 5px;
    width: 100%;
    margin: 0;
  }
`;

const StatHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StatIcon = styled.span`
  margin-right: 5px;
  font-size: 1.5rem;
  color: #ea864d;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-right: 5px;
  }
`;

const StatName = styled.span`
  color: #bdc3c7;
  text-align: left;
  flex: 1;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

const StatValue = styled.span`
  color: #ecf0f1;
  text-align: right;
  flex: 1;
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: 600px) {
    font-size: 0.9rem;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  height: 10px;
  margin-top: 5px;
`;

const ProgressBar = styled.div`
  height: 100%;
  background: #ea864d;
  border-radius: 5px;
  animation: ${({ width }) => grow(width)} 2s ease-in-out forwards;
`;

const ProgressPercentage = styled.span`
  margin-left: 10px;
  color: #ecf0f1;
  font-size: 0.9rem;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ea864d;
  margin: 10px 0;
`;

const SkillsTitle = styled.h3`
  margin: 0;
  color: #ea864d;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const AdminButton = styled.button`
  background-color: #28a745;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;

  &:hover {
    background-color: #218838;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const LogoutButton = styled.button`
  background-color: #ea864d;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;

  &:hover {
    background-color: #d97842;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const FinesButton = styled.button`
  background-color: #ea864d;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;

  &:hover {
    background-color: #d97842;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: rgba(23, 23, 23, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 760px;
  max-height: 80vh;
  overflow-y: auto;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
`;

const CloseButton = styled.button`
  background-color: #ea864d;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 10px;

  &:hover {
    background-color: #d97842;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const TableHeader = styled.th`
  color: #ea864d;
  font-family: 'Montserrat', sans-serif;
`;

const TableCell = styled.td`
  color: #ecf0f1;
  font-family: 'Montserrat', sans-serif;
`;

const PaginationButton = styled.button`
  background-color: #28a745;
  border: none;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 5px;

  &:hover {
    background-color: #218838;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

const ModalTitle = styled.h2`
  font-family: 'Montserrat', sans-serif;
  color: #ea864d;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  & th, & td {
    border: 1px solid #555;
    padding: 8px;
    text-align: left;
  }

  & th {
    background-color: rgba(23, 23, 23, 0.9);
  }
`;

const TotalFines = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: #ecf0f1;
  text-align: right;
  margin-top: 10px;
`;

const DonationStatsButton = styled.button`
  background-color: #007bff;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [showFines, setShowFines] = useState(false);
  const [fines, setFines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFines, setTotalFines] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data);
        setUser(response.data.user);
      } catch (error) {
        console.error('Ошибка при получении данных пользователя:', error);
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleShowFines = async (page = 1) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/myfines?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setFines(response.data.fines);
      setTotalFines(response.data.totalFines);
      setShowFines(true);
      setCurrentPage(page);
    } catch (error) {
      console.error('Ошибка при получении штрафов:', error);
      console.log(error.response.data);
    }
  };  

  const formatDateTime = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(dateString).toLocaleString('ru-RU', options);
  };

  const formatNumber = (number) => {
    return number.toLocaleString('ru-RU');
  };

  const getGender = (gender) => {
    return gender === 1 ? 'Мужской' : 'Женский';
  };

  const getOrgName = (orgId) => {
    const orgNames = {
      0: 'Гражданин',
      1: 'УМВД',
      2: 'ФСБ',
      3: 'Мотострелковая часть',
      4: 'Городская больница №1',
      5: 'Транспортная служба',
      6: 'Бригада',
      7: 'Администрация',
      8: 'Радио EuroLite',
      9: 'Радио Premier FM',
      10: 'Братский круг',
      11: 'Автошкола',
      12: 'Русская мафия',
      16: 'Шпана',
      17: 'Механик',
      18: 'Дальнобойщик',
      19: 'Дровосек',
      20: 'Водитель автобуса',
      21: 'Развозчик бытовой техники',
      22: 'Водитель мусоровоза',
      23: 'Снегоуборщик',
      24: 'Инкассатор'
    };
    return orgNames[orgId] || 'Неизвестная организация';
  };

  const getJobName = (jobId) => {
    const jobNames = {
      0: 'Безработный',
      1: 'Убрана',
      2: 'Наркодиллер',
      3: 'Автовор',
      4: 'Карманник',
      5: 'Водитель автобуса',
      6: 'Дровосек',
      7: 'Механик',
      8: 'Таксист',
      9: 'Оружейник',
      10: 'Дальнобойщик',
      11: 'Грибник',
      12: 'Грабитель домов',
      13: 'Развозчик бытовой техники',
      14: 'Электрик',
      15: 'Контрабандист',
      16: 'Рыболов',
      17: 'Охотник',
      18: 'Шахтер',
      19: 'Машинист поезда',
      20: 'Инкассатор',
      21: 'Водитель мусоровоза',
      22: 'Снегоуборщик',
      23: 'Пожарный'
    };
    return jobNames[jobId] || 'Безработный';
  };

  const getMobNum = (value) => {
    return value === 0 ? 'Отсутствует' : value;
  };

  const getRankName = (value) => {
    return value === 'NoRank' ? 'Отсутствует' : value;
  };

  const getPropertyValue = (value) => {
    return value === 999 ? 'Отсутствует' : value;
  };

  const getSkillPercentage = (skillValue) => {
    const clampedValue = Math.min(skillValue, 999);
    return (clampedValue / 999) * 100;
  };  

  const skills = [
    { name: 'PISTOL', value: user?.stSkill0 },
    { name: 'PISTOL S', value: user?.stSkill1 },
    { name: 'DEAGLE', value: user?.stSkill2 },
    { name: 'SHOTGUN', value: user?.stSkill3 },
    { name: 'SAWNOFF', value: user?.stSkill4 },
    { name: 'SPAS12', value: user?.stSkill5 },
    { name: 'MICRO UZI', value: user?.stSkill6 },
    { name: 'АКУ', value: user?.stSkill7 },
    { name: 'AK47', value: user?.stSkill8 },
    { name: 'Гроза', value: user?.stSkill9 },
    { name: 'SNIPER', value: user?.stSkill10 },
  ];

  if (!user) {
    return <Container>Загрузка...</Container>;
  }

  return (
    <Container>
      <Title>Личный кабинет</Title>
      <ProfileCard>
        <UserInfo>
          <UserName>
            {user.stName}
            {user.stVip === 1 && (
              <VIPBadge>
                <CrownIcon />
                <Tooltip className="tooltip">VIP Аккаунт</Tooltip>
              </VIPBadge>
            )}
          </UserName>
        </UserInfo>
        <UserID>ID: {user.stID}</UserID>
        <StatsGrid>
          <StatItem>
            <StatHeader>
              <StatIcon><FaLevelUpAlt /></StatIcon>
              <StatName>Уровень персонажа:</StatName>
              <StatValue>{user.stLevel}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaMoneyBillWave /></StatIcon>
              <StatName>Деньги:</StatName>
              <StatValue>{formatNumber(user.stBabki)} ₽</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaPiggyBank /></StatIcon>
              <StatName>Деньги в банке:</StatName>
              <StatValue>{formatNumber(user.stBank)} ₽</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaPiggyBank /></StatIcon>
              <StatName>Накопления:</StatName>
              <StatValue>{formatNumber(user.stNakop)} ₽</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaPhone /></StatIcon>
              <StatName>Номер телефона:</StatName>
              <StatValue>{getMobNum(user.stNumber)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaGift /></StatIcon>
              <StatName>Бонусы:</StatName>
              <StatValue>{formatNumber(user.stBonus)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaBuilding /></StatIcon>
              <StatName>Организация:</StatName>
              <StatValue>{getOrgName(user.stMember)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><GrUserWorker /></StatIcon>
              <StatName>Подработка:</StatName>
              <StatValue>{getJobName(user.stJob)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaBriefcase /></StatIcon>
              <StatName>Должность:</StatName>
              <StatValue>{getRankName(user.stRankName)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaGenderless /></StatIcon>
              <StatName>Пол:</StatName>
              <StatValue>{getGender(user.stSex)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaHome /></StatIcon>
              <StatName>Жилье:</StatName>
              <StatValue>{getPropertyValue(user.stHouse)}</StatValue>
            </StatHeader>
          </StatItem>
          <StatItem>
            <StatHeader>
              <StatIcon><FaStore /></StatIcon>
              <StatName>Бизнес:</StatName>
              <StatValue>{getPropertyValue(user.stBiz)}</StatValue>
            </StatHeader>
          </StatItem>
        </StatsGrid>
        <Divider />
        <SkillsTitle>Скиллы</SkillsTitle>
        <StatsGrid>
          {skills.map((skill, index) => (
            <StatItem key={index}>
              <StatHeader>
                <StatIcon><GiPistolGun /></StatIcon>
                <StatName>{skill.name}:</StatName>
                <ProgressPercentage>{getSkillPercentage(skill.value).toFixed(0)}%</ProgressPercentage>
              </StatHeader>
              <ProgressBarContainer>
                <ProgressBar width={`${getSkillPercentage(skill.value)}%`} />
              </ProgressBarContainer>
            </StatItem>
          ))}
        </StatsGrid>
        {user.stAdmin >= 1 && (
          <AdminButton onClick={() => navigate('/admin')}>Панель администратора</AdminButton>
        )}
        <FinesButton onClick={() => handleShowFines(1)}>Мои штрафы</FinesButton>
        {user.stName === 'Alexey_Kozyrev' && (
          <DonationStatsButton onClick={() => navigate('/donation-statistics')}>Статистика доната</DonationStatsButton>
        )}
        <LogoutButton onClick={handleLogout}>Выйти</LogoutButton>
      </ProfileCard>
      {showFines && (
      <ModalOverlay>
        <ModalContent>
          <ModalTitle>Мои штрафы</ModalTitle>
          <StyledTable>
            <thead>
              <tr>
                <TableHeader>Дата</TableHeader>
                <TableHeader>Нарушения</TableHeader>
                <TableHeader>Сумма</TableHeader>
                <TableHeader>Номер авто</TableHeader>
              </tr>
            </thead>
            <tbody>
              {fines.map((fine, index) => (
                <tr key={index}>
                  <TableCell>{formatDateTime(fine.timestamp)}</TableCell>
                  <TableCell>{fine.violation}</TableCell>
                  <TableCell>{fine.fine_amount}</TableCell>
                  <TableCell>{fine.vehicle_plate}</TableCell>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <TotalFines>Всего штрафов: {totalFines}</TotalFines>
          <div>
            {currentPage > 1 && (
              <PaginationButton onClick={() => handleShowFines(currentPage - 1)}>Предыдущая</PaginationButton>
            )}
            {fines.length === 5 && (
              <PaginationButton onClick={() => handleShowFines(currentPage + 1)}>Следующая</PaginationButton>
            )}
          </div>
          <CloseButton onClick={() => setShowFines(false)}>Закрыть</CloseButton>
        </ModalContent>
      </ModalOverlay>
    )}
    </Container>
  );
};

export default Dashboard;
