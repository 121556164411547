import React from 'react';
import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
`;

const ErrorIcon = styled(FaTimesCircle)`
  color: #e74c3c;
  font-size: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
`;

const Message = styled.p`
  font-size: 1.5rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Button = styled.a`
  background-color: #e74c3c;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #c0392b;
    transform: scale(1.05);
  }
`;

const PaymentFailure = () => {
  return (
    <Container>
      <ErrorIcon />
      <Title>УПС, ЧТО-ТО ПОШЛО НЕ ТАК</Title>
      <Message>Ваш платёж завершился неудачно. Пожалуйста, обратитесь в нашу поддержку</Message>
      <ButtonContainer>
        <Button href="https://vk.com/premier_game">ВКонтакте</Button>
        <Button href="https://forum.premier-game.ru/forums/rasprostranennye-problemy-razdel-reshenija-problem.16/">Технический раздел</Button>
      </ButtonContainer>
    </Container>
  );
};

export default PaymentFailure;