import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import backgroundImg from './assets/images/donate-bg.jpg';
import { FaCrown } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  background: url(${backgroundImg}) center/cover no-repeat;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  animation: ${fadeIn} 1s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 80px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  background: ${props => (props.active ? '#ea864d' : 'transparent')};
  color: ${props => (props.active ? 'white' : '#ea864d')};
  border: 2px solid #ea864d;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    background: #d97842;
    color: white;
  }
`;

const SearchBox = styled.input`
  padding: 10px;
  width: 300px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 2px solid #ea864d;
  font-size: 16px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-family: 'Montserrat', sans-serif;
  &:focus {
    outline: none;
    border-color: #d97842;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  max-width: 1200px;
`;

const Table = styled.table`
  width: 100%;
  background: rgba(23, 23, 23, 0.9);
  border-collapse: collapse;
  margin-bottom: 20px;
  color: #bdc3c7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const Th = styled.th`
  padding: 10px;
  background: #ea864d;
  color: black;
  border: 1px solid #ea864d;
  font-family: 'Montserrat', sans-serif;
`;

const Td = styled.td`
  padding: 10px;
  text-align: center;
  border: 1px solid #ea864d;
  font-family: 'Montserrat', sans-serif;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background: ${props => (props.disabled ? '#444' : '#ea864d')};
  color: ${props => (props.disabled ? '#888' : 'white')};
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    background: ${props => (props.disabled ? '#444' : '#d97842')};
  }
`;

const CrownIcon = styled(FaCrown)`
  color: ${props => (props.gold ? 'gold' : props.silver ? '#C0C0C0' : '#cd7f32')};
  margin-right: 5px;
`;

const Note = styled.p`
  font-size: 12px;
  color: #bdc3c7;
  text-align: left;
  margin-top: -10px;
  margin-left: 20px;
`;

const formatMoney = (amount) => {
  if (amount >= 1000000000) {
    return `${(amount / 1000000000).toFixed(3)}kkk`;
  } else if (amount >= 1000000) {
    return `${(amount / 1000000).toFixed(3)}kk`;
  } else if (amount >= 1000) {
    return `${(amount / 1000).toFixed(1)}k`;
  }
  return amount;
};

const formatDate = (dateString) => {
  if (!dateString || dateString === 'Дата не указана') {
    return 'Дата не указана';
  }
  try {
    const dateParts = dateString.split('/');
    const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    return formattedDate.toLocaleDateString('ru-RU');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Неверный формат даты';
  }
};

const Statistics = () => {
  const [activeTab, setActiveTab] = useState('Семьи');
  const [families, setFamilies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [richestPlayers, setRichestPlayers] = useState([]);
  const [goldPlayers, setGoldPlayers] = useState([]);

  useEffect(() => {
    const fetchFamilies = async () => {
      try {
        const response = await axios.get('/api/families', {
          params: {
            search: searchTerm,
            page: currentPage
          }
        });
        setFamilies(response.data.families);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching families:', error);
      }
    };

    if (activeTab === 'Семьи') {
      fetchFamilies();
    }
  }, [activeTab, searchTerm, currentPage]);

  useEffect(() => {
    const fetchRichestPlayers = async () => {
      try {
        const response = await axios.get('/api/richest-players');
        setRichestPlayers(response.data);
      } catch (error) {
        console.error('Error fetching richest players:', error);
      }
    };

    if (activeTab === 'Forbes') {
      fetchRichestPlayers();
    }
  }, [activeTab]);

  useEffect(() => {
    const fetchGoldPlayers = async () => {
      try {
        const response = await axios.get('/api/gold-players');
        setGoldPlayers(response.data);
      } catch (error) {
        console.error('Error fetching gold players:', error);
      }
    };

    if (activeTab === 'Золото') {
      fetchGoldPlayers();
    }
  }, [activeTab]);

  return (
    <Container>
      <ContentWrapper>
        <Title>Статистика</Title>
        <Tabs>
          <Tab active={activeTab === 'Семьи'} onClick={() => setActiveTab('Семьи')}>Семьи</Tab>
          <Tab active={activeTab === 'Forbes'} onClick={() => setActiveTab('Forbes')}>Forbes</Tab>
          <Tab active={activeTab === 'Золото'} onClick={() => setActiveTab('Золото')}>Золото</Tab>
        </Tabs>
        {activeTab === 'Семьи' && (
          <>
            <SearchBox
              type="text"
              placeholder="Поиск семей..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Th>ID</Th>
                    <Th>Название семьи</Th>
                    <Th>Владелец</Th>
                    <Th>Статус</Th>
                    <Th>Количество членов</Th>
                    <Th>Дата регистрации</Th>
                  </tr>
                </thead>
                <tbody>
                  {families.map(family => (
                    <tr key={family.faID}>
                      <Td>{family.faID}</Td>
                      <Td>{family.faName}</Td>
                      <Td>{family.faOrName}</Td>
                      <Td>{family.faStatus === 1 ? 'Активна' : 'Неактивна'}</Td>
                      <Td>{family.faPeople}</Td>
                      <Td>{formatDate(family.faDate)}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
            <Pagination>
              <PageButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                Назад
              </PageButton>
              <PageButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                Вперёд
              </PageButton>
            </Pagination>
          </>
        )}
        {activeTab === 'Forbes' && (
          <>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Th style={{ width: '10%' }}>№</Th>
                    <Th>Ник-нейм</Th>
                    <Th>Состояние</Th>
                  </tr>
                </thead>
                <tbody>
                  {richestPlayers.map((player, index) => (
                    <tr key={player.stID} style={{ backgroundColor: index < 3 ? '#2f2f2f' : 'transparent' }}>
                      <Td>
                        {index === 0 && <CrownIcon gold />}
                        {index === 1 && <CrownIcon silver />}
                        {index === 2 && <CrownIcon bronze />}
                        {index + 1}
                      </Td>
                      <Td>{player.stName}</Td>
                      <Td>{formatMoney(player.stBabki + player.stBank)} ₽</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
            <Note>* Учитывается общая имеющаяся сумма наличкой и в банке</Note>
          </>
        )}
        {activeTab === 'Золото' && (
          <>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Th style={{ width: '10%' }}>№</Th>
                    <Th>Ник-нейм</Th>
                    <Th>Бонусы</Th>
                  </tr>
                </thead>
                <tbody>
                  {goldPlayers.map((player, index) => (
                    <tr key={player.stID} style={{ backgroundColor: index < 3 ? '#2f2f2f' : 'transparent' }}>
                      <Td>
                        {index === 0 && <CrownIcon gold />}
                        {index === 1 && <CrownIcon silver />}
                        {index === 2 && <CrownIcon bronze />}
                        {index + 1}
                      </Td>
                      <Td>{player.stName}</Td>
                      <Td>{formatMoney(player.stBonus)}</Td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Statistics;