import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
`;

const SuccessIcon = styled(FaCheckCircle)`
  color: #4caf50;
  font-size: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
`;

const Message = styled.p`
  font-size: 1.5rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const PaymentSuccess = () => {
  return (
    <Container>
      <SuccessIcon />
      <Title>ПЛАТЁЖ УСПЕШНО СОВЕРШЁН</Title>
      <Message>Бонусы успешно зачислены на аккаунт</Message>
    </Container>
  );
};

export default PaymentSuccess;