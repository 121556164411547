import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

const Container = styled.div`
  padding: 20px;
  padding-top: 80px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: #ea864d;
  text-align: center;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 40px;

  & th, & td {
    border: 1px solid #555;
    padding: 8px;
    text-align: left;
  }

  & th {
    background-color: rgba(23, 23, 23, 0.9);
  }

  & td {
    color: #ecf0f1;
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-family: 'Montserrat', sans-serif;
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  & th, & td {
    border: 1px solid #555;
    padding: 8px;
    text-align: left;
  }

  & th {
    background-color: rgba(23, 23, 23, 0.9);
  }

  & td {
    color: #ecf0f1;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 20px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: #ea864d;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  transition: background-color 0.3s, transform 0.3s;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: #d97842;
    transform: scale(1.05);
  }
`;

const DonationStatistics = () => {
  const [donations, setDonations] = useState([]);
  const [search, setSearch] = useState('');
  const [summary, setSummary] = useState({});
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchDonations();
    fetchSummary();
  }, []);

  const fetchDonations = async (searchTerm = '', page = 1) => {
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/donations`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { search: searchTerm, page }
      });
      setDonations(response.data.donations);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching donations:', error);
      setError('Ошибка загрузки данных');
    }
  };

  const fetchSummary = async () => {
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/donations-summary`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setSummary(response.data);
    } catch (error) {
      console.error('Error fetching donations summary:', error);
      setError('Ошибка загрузки данных');
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    fetchDonations(searchTerm);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchDonations(search, newPage);
  };

  const formatDateTime = (dateString) => {
    return format(new Date(dateString), 'dd.MM.yyyy HH:mm:ss', { locale: ru });
  };

  const formatNumber = (number) => {
    return Number(number).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <Container>
      <Title>Статистика доната</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <SearchInput
        type="text"
        placeholder="Поиск по никнейму"
        value={search}
        onChange={handleSearchChange}
      />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Никнейм</th>
            <th>Сумма</th>
            <th>Метод</th>
            <th>Создан</th>
            <th>Оплачен</th>
          </tr>
        </thead>
        <tbody>
          {donations.map((donation) => (
            <tr key={donation.id}>
              <td>{donation.id}</td>
              <td>{donation.account}</td>
              <td>{formatNumber(donation.money)} руб.</td>
              <td>{donation.method}</td>
              <td>{formatDateTime(donation.dateCreate)}</td>
              <td>{donation.dateComplete ? formatDateTime(donation.dateComplete) : '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationContainer>
        {currentPage > 1 && (
          <PaginationButton onClick={() => handlePageChange(currentPage - 1)}>Назад</PaginationButton>
        )}
        {currentPage < totalPages && (
          <PaginationButton onClick={() => handlePageChange(currentPage + 1)}>Вперёд</PaginationButton>
        )}
      </PaginationContainer>
      <SummaryTable>
        <thead>
          <tr>
            <th>Метод</th>
            <th>Сегодня</th>
            <th>Вчера</th>
            <th>Прошлый месяц</th>
            <th>Текущий месяц</th>
            <th>Прошлый год</th>
            <th>Текущий год</th>
            <th>Всего</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(summary).map((method) => (
            <tr key={method}>
              <td>{method}</td>
              <td>{formatNumber(summary[method]?.today || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.yesterday || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.lastMonth || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.currentMonth || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.lastYear || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.currentYear || 0)} руб.</td>
              <td>{formatNumber(summary[method]?.allTime || 0)} руб.</td>
            </tr>
          ))}
        </tbody>
      </SummaryTable>
    </Container>
  );
};

export default DonationStatistics;