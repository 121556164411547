import React, { useEffect } from 'react';
import styled from 'styled-components';
import mapImage from './assets/images/map.jpg';
import backgroundImage from './assets/images/background.jpg';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 35px;
  margin-bottom: 20px;
  font-family: 'Acrom', sans-serif;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 8px;
`;

const HighlightedText = styled.span`
  background-color: #ea864d;
  box-shadow: 0 5px 50px #ea864d;
  padding: 0 10px;
  border-radius: 4px;
`;

const MapImage = styled.img`
  max-width: 100%;
  width: 1200px;
  height: auto;
  border-radius: 8px;
`;

const Map = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Title>Карта <HighlightedText>Премьерской</HighlightedText> области</Title>
      <MapImage src={mapImage} alt="Map" />
    </Container>
  );
};

export default Map;
