import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import backgroundImg from './assets/images/background.jpg';

const HeroContainer = styled.section`
  background: url(${backgroundImg}) center/cover no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  padding: 0 20px;
`;

const HeroTitle = styled.h1`
  font-size: 70px;
  font-weight: 800;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

const HeroText = styled.p`
  margin: 1rem 0;
  font-size: 20px;
  color: #bebebe;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Highlight = styled.span`
  color: #ea864d;
`;

const HeroButton = styled(ScrollLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: #ea864d;
  color: #171717;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Acrom', sans-serif;
  box-shadow: 10px 0px 70px #ea864d;

  &:hover {
    background: #d97842;
    transform: scale(1.05);
    color: #fff;
    box-shadow: 10px 0px 70px #d97842;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
  }
`;

const HeroRouterButton = styled(RouterLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: #ea864d;
  color: #171717;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Acrom', sans-serif;
  box-shadow: 10px 0px 70px #ea864d;

  &:hover {
    background: #d97842;
    transform: scale(1.05);
    color: #fff;
    box-shadow: 10px 0px 70px #d97842;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Добавлен gap для разъединения кнопок */
  margin-top: 70px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const HeroSection = () => (
  <HeroContainer>
    <HeroTitle>PREMIER GAME</HeroTitle>
    <HeroText>
      Проект русского города <Highlight>Премьерск</Highlight>, с собственной <u>картой</u> и мультиплеером SA:MP.
    </HeroText>
    <ButtonGroup>
      <HeroButton to="how-to-start" smooth duration={1000}>Начать играть &#10003;</HeroButton>
      <HeroRouterButton to="/map">Карта города &#128270;</HeroRouterButton>
    </ButtonGroup>
  </HeroContainer>
);

export default HeroSection;