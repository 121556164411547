import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import donateBg from './assets/images/donate-bg.jpg';
import bgRazn from './assets/images/bg__razn.png';
import bgVip from './assets/images/bg__vip.png';
import bgCar from './assets/images/bg__car.png';
import bgPers from './assets/images/bg__pers.png';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const ShopContainer = styled.section`
  background: url(${donateBg}) center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
`;

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
`;

const FormBackground = styled.div`
  position: relative;
  width: 100%;
  padding: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const DonateTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 35px;
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  bottom: 10px;
  left: 20px;
  top: -5px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25px;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  color: #e2e2e2;
  transition: all .40s;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Input = styled.input`
  border: none;
  appearance: none;
  border-radius: 5px;
  height: 60px;
  width: 90%;
  border: 1px solid #898989;
  padding: 0px 20px;
  background-color: transparent;
  transition: 0.6s;
  font-family: 'Montserrat', sans-serif;
  color: #e2e2e2;
  font-size: 17px;
  outline: none;
  transition: all .40s;

  &:focus {
    border: 1px solid #fff;
  }

  &:hover {
    border: 1px solid #fff;
    transition: all .40s;
  }

  &:focus ~ ${Label}, 
  &:not(:placeholder-shown) ~ ${Label} {
    left: 10px;
    top: -50px;
    color: #898989;
    font-size: 14px;
    transition: all .40s;
  }

  @media (max-width: 480px) {
    height: 50px;
    font-size: 16px;
  }
`;

const Select = styled.select`
  border: none;
  appearance: none;
  border-radius: 5px;
  height: 60px;
  width: 100%;
  border: 1px solid #898989;
  padding: 0px 20px;
  background-color: transparent;
  transition: 0.6s;
  font-family: 'Montserrat', sans-serif;
  color: #e2e2e2;
  font-size: 17px;
  outline: none;
  transition: all .40s;

  &:focus {
    border: 1px solid #fff;
  }

  &:hover {
    border: 1px solid #fff;
    transition: all .40s;
  }

  option {
    background: #333;
    color: #fff;
  }

  @media (max-width: 480px) {
    height: 50px;
    font-size: 16px;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f3f3f3;
  margin-bottom: 35px;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  width: 100%;
  cursor: pointer;
  padding: 22px;
  background-color: #ea864d;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #171717;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 0 75px #ea864d;
  transition: .90s;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0 0 40px #ea864d;
    transition: .90s;
  }

  @media (max-width: 480px) {
    padding: 18px;
    font-size: 16px;
  }
`;

const BonusInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const BonusInfoText = styled.span`
  color: #e2e2e2;
  font-weight: 300;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const BonusInfoValue = styled.span`
  background-color: #e2e2e2c2;
  padding: 10px;
  color: #171717;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;

  @media (max-width: 480px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const InfoText = styled.p`
  color: #e2e2e2;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
`;

const WhatToBuyContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: url(${donateBg}) center no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 100px 20px;
  box-sizing: border-box;

  @media (max-width: 480px) {
    padding: 50px 20px;
  }
`;

const WhatToBuyTitle = styled.h2`
  font-size: 30px;
  font-weight: 500;
  color: #e2e2e2;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  display: block;
  position: relative;
  width: calc(50% - 20px);
  height: 390px;
  margin: 10px;
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #e2e2e2;
  cursor: default;
  text-align: left;
  outline: none;
  transition: 0.5s;
  user-select: none;
  overflow: hidden;
  animation: ${fadeInUp} 0.8s ease-out;
  opacity: 0;
  animation-fill-mode: forwards;

  &.visible {
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ gradient }) => gradient};
    z-index: 1;
    border-radius: 20px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ $bgImage }) => `url(${$bgImage})`} center no-repeat;
    background-size: cover;
    opacity: 0.4;
    z-index: 2;
    border-radius: 20px;
    transition: transform 0.5s ease;
  }

  &:hover::after {
    transform: scale(1.1);
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  > * {
    position: relative;
    z-index: 3;
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
    height: auto;
    font-size: 14px;
    padding: 10px;
  }
`;

const CardTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const CardContent = styled.div`
  color: #e2e2e2;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 30px;

  @media (max-width: 480px) {
    font-size: 12px;
    margin-left: 0;
    text-align: center;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 10px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    a {
      margin-bottom: 10px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #bebebe;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-shadow: 0 2px 10px white;
  }
`;

const Shop = () => {
  const [formData, setFormData] = useState({
    account: '',
    sum: '',
    customerEmail: '',
    method: 'unitpay',
    rules: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseFloat(formData.sum) < 5) {
      alert('Минимальная сумма для пополнения - 5 рублей.');
      return;
    }

    try {
      const response = await fetch('https://premier-game.ru/api/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (data.payment_link) {
        window.location.href = data.payment_link;
      } else {
        alert(data.error || 'Ошибка при создании платежа');
      }
    } catch (error) {
      console.error('Error creating payment:', error);
      alert('Ошибка при создании платежа');
    }
  };

  const calculateBonuses = (sum) => {
    return sum ? sum * 100 : 0;
  };

  const whatToBuyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.card');
      elements.forEach(el => {
        const rect = el.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.8) {
          el.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const redirectToTerms = () => {
    navigate('/terms-of-service');
  };

  return (
    <div>
      <ShopContainer>
        <FormContainer>
          <FormBackground>
            <DonateTitle>Пополнить счёт</DonateTitle>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  type="text"
                  name="account"
                  value={formData.account}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <Label>Ник на сервере</Label>
              </FormGroup>
              <FormGroup>
                <Input
                  type="number"
                  name="sum"
                  value={formData.sum}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <Label>Сумма в рублях</Label>
              </FormGroup>
              <BonusInfoContainer>
                <BonusInfoText>Вы получите:</BonusInfoText>
                <BonusInfoValue>{calculateBonuses(formData.sum)} бонусов</BonusInfoValue>
              </BonusInfoContainer>
              <FormGroup>
                <Input
                  type="email"
                  name="customerEmail"
                  value={formData.customerEmail}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <Label>Почта Email для отправки чека</Label>
              </FormGroup>
              <FormGroup>
                <Select
                  name="method"
                  value={formData.method}
                  onChange={handleChange}
                  required
                >
                  <option value="unitpay">СБП / Банковские карты</option>
                </Select>
              </FormGroup>
              <CheckboxLabel>
                <Checkbox
                  type="checkbox"
                  name="rules"
                  checked={formData.rules}
                  onChange={handleChange}
                  required
                />
                Согласен с условиями проекта.
              </CheckboxLabel>
              <InfoText>Для зачисления бонусов на аккаунт после оплаты введите команду "/bonus" в игре или перезайдите.</InfoText>
              <Button type="submit">Перейти к оплате</Button>
              <LinkContainer>
                <StyledLink to="whatToBuy" smooth={true} duration={500}>Что купить?</StyledLink>
                <StyledLink as="a" onClick={redirectToTerms}>Условия проекта</StyledLink>
              </LinkContainer>
            </form>
          </FormBackground>
        </FormContainer>
      </ShopContainer>

      <WhatToBuyContainer id="whatToBuy" ref={whatToBuyRef}>
        <WhatToBuyTitle>Что можно приобрести?</WhatToBuyTitle>
        <CardContainer>
          <Card className="card" $bgImage={bgRazn} gradient="linear-gradient(147.9deg, rgb(255, 152, 2) -13.22%, rgb(181, 26, 26) 81.17%)">
            <CardTitle>Разное</CardTitle>
            <CardContent>
              &#183; Стартовый набор новичка — 10.000 бонусов <br />
              &#183; Обмен бонусов на игровую валюту — 100 бонусов = 30.000 игровой валюты(60.000 с VIP) <br />
              &#183; Уменьшение времени бана — 10.000 бонусов <br />
              &#183; Установка ворот на коттедж — 20.000 бонусов <br />
              &#183; Индивидуальное изменение территории у коттеджа — от 500.000 бонусов <br />
            </CardContent>
          </Card>
          <Card className="card" $bgImage={bgCar} gradient="linear-gradient(147.9deg, rgb(243, 192, 116) -13.22%, rgb(166, 181, 26) 81.17%)">
            <CardTitle>К автомобилю</CardTitle>
            <CardContent>
              &#183; Уникальный государственный номер с выбором региона и страны — 50.000 бонусов <br />
              &#183; Эксклюзивные номерные рамки (мин. 5 авто) — 100.000 бонусов <br />
              &#183; Уникальный цвет для вашего автомобиля — 10.000 бонусов <br />
              &#183; Нанесение / изменение надписей — 20.000 бонусов <br />
              &#183; СГУ (крякалка) - мигалки — от 300.000 бонусов
            </CardContent>
          </Card>
          <Card className="card" $bgImage={bgVip} gradient="linear-gradient(147.9deg, rgb(87, 141, 247) -13.22%, rgb(94, 14, 107) 81.17%)">
            <CardTitle>VIP статус — 50.000 бонусов</CardTitle>
            <CardContent>
              &#183; Возможность иметь более одного транспортного средства<br />
              &#183; Выход из фракции без ожидания лидера<br />
              &#183; X2 умножение при конвертации бонусов в игровую валюту<br />
              &#183; Более подробно смотрите в меню (/mm) на сервере<br />
            </CardContent>
          </Card>
          <Card className="card" $bgImage={bgPers} gradient="linear-gradient(331.92deg, rgb(86, 40, 72) 7.5%, rgb(122, 51, 51) 96.47%)">
            <CardTitle>Персонажу</CardTitle>
            <CardContent>
              &#183; Уникальный номер телефона — 10.000 бонусов <br />
              &#183; Прокачка всех навыков оружия — 5000 бонусов <br />
              &#183; Смена имени и фамилии — 20.000 бонусов <br />
              &#183; Снятие блокировки фракций — 10.000 бонусов <br />
              &#183; Снять ограничение времени на покупку авто — 10.000 бонусов
            </CardContent>
          </Card>
        </CardContainer>
      </WhatToBuyContainer>
    </div>
  );
};

export default Shop;
