import { createGlobalStyle } from 'styled-components';

const ScrollbarStyles = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: #171717;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ea864d;
    outline: 1px solid #171717;
    opacity: 0.7;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff8c00;
  }
`;

export default ScrollbarStyles;
