import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import backgroundImage from './assets/images/pass-bg.png';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  animation: ${fadeIn} 1s ease-in-out;
  padding: 20px;
`;

const FormWrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 15px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
  transition: background 0.3s, box-shadow 0.3s;

  &:focus {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px #ea864d;
    outline: none;
  }

  &::placeholder {
    color: #ccc;
  }
`;

const Button = styled.button`
  padding: 15px;
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background: #ea864d;
  color: #171717;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background: #d97842;
    transform: scale(1.05);
    color: #fff;
    box-shadow: 0 0 20px #ea864d;
  }
`;

const Message = styled.p`
  color: ${(props) => (props.$error ? 'red' : 'green')};
  font-size: 16px;
  margin-top: 20px;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #ccc;
  margin-top: 10px;
  text-align: center;

  a {
    color: #ea864d;
    text-decoration: underline;
  }
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://premier-game.ru/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, login, recaptchaToken }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage('Ошибка при отправке запроса');
    }
  };

  return (
    <Container>
      <FormWrapper>
        <h1>Восстановление пароля</h1>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Ник на сервере"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
          <Input
            type="email"
            placeholder="Почта Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <ReCAPTCHA
            sitekey="6LcVCP8pAAAAADfWqIDKBfeZf7eDzBtmkOTN2osP"
            onChange={(token) => setRecaptchaToken(token)}
          />
          <Button type="submit" disabled={!recaptchaToken}>Отправить письмо</Button>
        </Form>
        {message && <Message $error={message.startsWith('Ошибка')}>{message}</Message>}
        <InfoText>* Ни в коем случае не сообщайте свой адрес электронной почты третьим лицам</InfoText>
        <InfoText>* В случае возникновения проблем — обратитесь в <a href="https://vk.com/premier_game">Сообщения группы ВКонтакте</a></InfoText>
      </FormWrapper>
    </Container>
  );
};

export default ForgotPassword;
