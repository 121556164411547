import React, { useState } from 'react';
import styled from 'styled-components';
import { FaGift } from 'react-icons/fa';

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(100px)')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: 1000;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
    padding: 10px 15px;
    font-size: 12px;
  }
`;

const GiftIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 1000;
  display: ${({ show }) => (show ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
    padding: 8px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
  outline: none;

  @media (max-width: 768px) {
    font-size: 14px;
    top: 3px;
    right: 5px;
  }
`;

const Notification = () => {
  const [showNotification, setShowNotification] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  return (
    <>
      <GiftIcon show={showNotification} onClick={toggleNotification}>
        <FaGift size={24} />
      </GiftIcon>
      <NotificationContainer show={showNotification}>
        <CloseButton onClick={toggleNotification}>×</CloseButton>
        Используйте промокод <strong>START</strong> для быстрого развития!
      </NotificationContainer>
    </>
  );
};

export default Notification;
