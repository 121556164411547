import React from 'react';
import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa';
import backgroundImage from './assets/images/background.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  text-align: center;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
`;

const ErrorIcon = styled(FaExclamationTriangle)`
  color: #e74c3c;
  font-size: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
`;

const Message = styled.p`
  font-size: 1.5rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const Button = styled.a`
  background-color: #ea864d;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #FF8C00;
    transform: scale(1.05);
  }
`;

const NotFound = () => {
  return (
    <Container>
      <ErrorIcon />
      <Title>404 - Страница не найдена</Title>
      <Message>Извините, но страница, которую вы ищете, не существует.</Message>
      <Button href="/">Вернуться на главную</Button>
    </Container>
  );
};

export default NotFound;