import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import DonationStatistics from './DonationStatistics';

const checkUserStatus = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data.user.stName === 'Alexey_Kozyrev';
  } catch (error) {
    console.error('Error checking user status:', error);
    return false;
  }
};

const DonationStatisticsRoute = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      const userStatus = await checkUserStatus();
      setIsAllowed(userStatus);
      setLoading(false);
    };

    checkAccess();
  }, []);

  if (loading) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  return isAllowed ? (
    <DonationStatistics />
  ) : (
    <AccessDeniedContainer>
      <AccessDeniedMessage>Доступ запрещен. У вас нет прав на просмотр этой страницы.</AccessDeniedMessage>
    </AccessDeniedContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  font-family: 'Montserrat', sans-serif;
`;

const AccessDeniedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #121212;
  color: white;
  font-family: 'Montserrat', sans-serif;
`;

const AccessDeniedMessage = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid red;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: red;
`;

export default DonationStatisticsRoute;